import { waitForFont } from "./fonts";

let prevIsTallScreen;
window.addEventListener("DOMContentLoaded", () => {
	prevIsTallScreen = isTallScreen();
});

let hasResizeHandler = false;
let prevOptions;
export function generateLogo(options) {
	const p = options.fontName ? waitForFont(options.fontName, options.fontUrl) : Promise.resolve();
	p.then(() => {
		const displayEl = document.querySelector("main");

		updateAndResize(displayEl, options);
		prevOptions = options;
		// add a classname so the image generator knows fonts are loaded
		document.body.classList.add("loaded");

		if (!hasResizeHandler) {
			hasResizeHandler = true;
			// Add event handler for future resizes
			let resizePending = false;
			window.addEventListener("resize", () => {
				if (resizePending) return;
				resizePending = true;
				if (prevIsTallScreen !== isTallScreen()) {
					prevIsTallScreen = isTallScreen();
					updateAndResize(displayEl, prevOptions);
				} else {
					resizeElement(document.querySelector("main"));
				}
				requestAnimationFrame(() => {
					resizePending = false;
				});
			});
		}
	});
}

export function getImageDownloadUrl() {
	return prevOptions && prevOptions.downloadUrl;
}

export function getPrevIndex() {
	return prevOptions && prevOptions.prevIndex;
}

export function getNextIndex() {
	return prevOptions && prevOptions.nextIndex;
}

function resizeElement(el) {
	if (!el.innerText.trim().length) return; // avoid an infinite loop

	// TODO: this would probably benefit from some caching, I just don't want to write it for
	// an edge case. People aren't gonna be resizing their windows a bunch.
	const maxWidth = 0.8 * document.documentElement.offsetWidth;
	const footerTop = document.querySelector("footer").offsetTop;
	const maxHeight = footerTop ? 0.8 * footerTop : document.documentElement.offsetHeight;

	// Figure out if we're sizing up or down
	const initialFontSize = parseInt(getComputedStyle(document.querySelector("main"))["font-size"], 10) || 10;
	const initialRect = el.getClientRects()[0];
	if (initialRect.width > maxWidth || initialRect.height > maxHeight) {
		// Size down
		const MIN_FONT_SIZE = 6;
		let fontSize = initialFontSize;
		let prevHeight = -1;
		let prevWidth = -1;
		while (fontSize > MIN_FONT_SIZE) {
			el.style.fontSize = `${fontSize}px`;
			const rect = el.getClientRects()[0];
			if (rect.width <= maxWidth && rect.height <= maxHeight) break;
			prevHeight = rect.height;
			prevWidth = rect.width;
			fontSize--;
		}

		el.style.fontSize = `${fontSize}px`;
	} else {
		// Size up
		const MAX_FONT_SIZE = 600;
		let fontSize = initialFontSize;
		while (fontSize < MAX_FONT_SIZE) {
			el.style.fontSize = `${fontSize}px`;
			const rect = el.getClientRects()[0];
			if (rect.width > maxWidth || rect.height > maxHeight) break;
			fontSize++;
		}

		// At the point that we broke out of the loop, we had just made the element slightly
		// too large, so the desired font size is one notch smaller.
		el.style.fontSize = `${fontSize - 1}px`;
	}

	// Reposition, too
	const rect = el.getClientRects()[0];
	const inset = Math.max(
		Math.floor(0.1 * document.documentElement.offsetWidth),
		Math.floor(0.1 * document.documentElement.offsetHeight)
	);
	const maxInsetHeight = Math.floor((document.documentElement.offsetHeight - rect.height) / 2);
	const maxInsetWidth = Math.floor((document.documentElement.offsetWidth - rect.width) / 2);
	const fillWidth = document.documentElement.offsetWidth;
	const fillHeight = document.documentElement.offsetHeight;
	el.style.left = `${(fillWidth - rect.width) / 2}px`;
	el.style.top = `${(fillHeight - rect.height) / 2}px`;
}

function isFancyWord(word) {
	return ["&", "and", "the", "or"].includes(word.toLowerCase());
}

function escapeHTML(input) {
	const el = document.createElement("span");
	el.innerText = input;
	return el.innerHTML;
}

function updateAndResize(el, options) {
	document.title = `${options.name.replace(/___/g, " ")} — So Many Food Trucks!`;
	const newlines = options.newlines && isTallScreen();

	el.innerHTML = ""; // remove existing children
	el.style.fontFamily = options.fontName || "";
	el.classList.toggle("newlines", newlines);
	el.classList.toggle("centre", newlines && options.centre);
	el.classList.toggle("colourtitle", options.colourtitle);
	el.classList.toggle("colourwords", options.colourwords);
	el.classList.toggle("lowercase", options.case === "lowercase");
	el.classList.toggle("uppercase", options.case === "uppercase");

	// Convert title into a series of DOM nodes, e.g.
	// 	"Hello world"
	// becomes
	// 	<span>Hello</span> <span>world</span>
	let words = options.name.split(" ");
	words.forEach((word, i) => {
		if (i > 0) el.appendChild(document.createTextNode(" "));
		const wordEl = document.createElement("span");
		if (options.ampersands && word === "and") {
			wordEl.innerText = "&";
		} else if (word.includes("___")) {
			wordEl.innerHTML = escapeHTML(word).replace(/___/g, "&nbsp;");
		} else {
			wordEl.innerText = word;
		}
		if (options.fancy && isFancyWord(word)) wordEl.classList.add("fancy");

		el.appendChild(wordEl);
	});

	// Set background on the document node
	document.documentElement.className = options.backgroundClassName || "";

	// Show/hide the download button
	const downloadEl = document.querySelector("button#download");
	if (downloadEl) downloadEl.classList.toggle("hidden", !options.downloadUrl);

	resizeElement(el);
}

function isTallScreen() {
	return document.body.offsetHeight > document.body.offsetWidth;
}

/**
 * Array of indexes that the API endpoint has previously returned to us. We send this along as a
 * hint so the backend can try to send us novel names.
 * @type {String[]}
 */
const previouslySeenIndexes = [];

export function fetchAndDisplayName(index = undefined) {
	let url = "/api/truck";
	if (index !== undefined) {
		url += `?index=${encodeURIComponent(index)}`;
	} else {
		url += `?seen=${encodeURIComponent(previouslySeenIndexes.join("."))}`;
	}

	return fetch(url)
		.then((response) => response.json())
		.then((options) => {
			if (!previouslySeenIndexes.includes(options.index)) {
				previouslySeenIndexes.unshift(options.index);
			}

			generateLogo(options);
			if (location.pathname !== options.path) {
				history.pushState(null, document.title, options.path + location.search + location.hash);
			}
		});
}
