import FontFaceObserver from "fontfaceobserver";

const fontPromises = {};

export function waitForFont(fontName, fontUrl) {
	if (!fontName || !fontUrl) return Promise.resolve();
	if (fontPromises[fontName]) return fontPromises[fontName];

	const font = new FontFaceObserver(fontName);

	fontPromises[fontName] = new Promise((resolve) => {
		font.load().then(resolve);
	});

	const linkEl = document.createElement("link");
	linkEl.setAttribute("rel", "stylesheet");
	linkEl.setAttribute("href", fontUrl);
	document.body.appendChild(linkEl);

	// Create a hidden element that references the font so it actually loads
	const fontEl = document.createElement("span");
	fontEl.style.visibility = "hidden";
	fontEl.style.fontFamily = fontName;
	fontEl.innerText = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
	document.body.appendChild(fontEl);

	return fontPromises[fontName];
}
