import { getImageDownloadUrl, fetchAndDisplayName, getPrevIndex, getNextIndex, generateLogo } from "./main";

function initEventListeners() {
	window.addEventListener("popstate", () => {
		if (!location.pathname.startsWith("/t/")) {
			console.log(
				"popstate led us to something that isn't a name; reload since we don't know how to handle this incrementally"
			);
			location.reload();
		}
		fetchAndDisplayName(location.pathname.slice(3));
	});

	window.addEventListener("keyup", (e) => {
		const N_KEY = 78;
		const ONE_KEY = 49;
		const P_KEY = 80;
		const PERIOD_KEY = 190;
		const R_KEY = 82;
		if (e.keyCode === N_KEY) {
			e.preventDefault();
			const index = getNextIndex();
			fetchAndDisplayName(index);
		} else if (e.keyCode === P_KEY) {
			e.preventDefault();
			const index = getPrevIndex();
			fetchAndDisplayName(index);
		} else if (e.keyCode === ONE_KEY) {
			e.preventDefault();
			const NUM_BACKGROUNDS = 14;
			const currentBackgroundIndex = parseInt(document.documentElement.className.replace("bg", ""), 10);
			const nextBackgroundIndex = currentBackgroundIndex === NUM_BACKGROUNDS ? 1 : currentBackgroundIndex + 1;
			document.documentElement.className = `bg${nextBackgroundIndex}`;
		} else if (e.keyCode === R_KEY || e.keyCode === PERIOD_KEY) {
			e.preventDefault();
			fetchAndDisplayName();
		}
	});

	document.querySelector("button#download").addEventListener("click", (e) => {
		e.preventDefault();

		const downloadUrl = getImageDownloadUrl();
		if (!downloadUrl) return;

		const link = document.createElement("a");
		link.href = downloadUrl;
		link.download = "";
		link.click();
	});

	document.querySelector("button#refresh").addEventListener("click", (e) => {
		e.preventDefault();
		document.querySelector("button#refresh").disabled = true;
		const t0 = performance.now();
		fetchAndDisplayName()
			.catch(() => {})
			.then(() => {
				// make sure the spinner goes around an even number of times
				const elapsedMs = performance.now() - t0;
				const SPIN_INTERVAL_MS = 500;
				const DELAY_MS = SPIN_INTERVAL_MS - (elapsedMs % 1000);
				setTimeout(() => (document.querySelector("button#refresh").disabled = false), DELAY_MS);
			});
	});
}

window.addEventListener("DOMContentLoaded", () => {
	// Render the logo
	if (!location.pathname.startsWith("/t/")) {
		// No name was specified in the URL, which is surprising. This shouldn't ever
		// happen; redirect to /t/bb to try to get back to a good state.
		location = "/t/0";
		return;
	}

	// A name was specified in the URL, so fetch the properties for it
	try {
		const options = JSON.parse(document.querySelector("#initial-name-options").innerText);
		generateLogo(options);
	} catch (err) {
		// Fetch a name from the backend
		fetchAndDisplayName(location.pathname.slice(3));
	}

	initEventListeners();
});
